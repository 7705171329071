import * as React from "react";
import styled from "styled-components";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  createLogOutTimer,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import backgroundImage from "../images/web-bg.jpg";
import { AccountInfo } from "../components/DataModels";
import { HeraldAPI, WardenAPI } from "../api-calls";

const StyledTextContainer = styled.div`
  max-width: 1025px;
  padding-inline: 10px;

  & h1 {
    font-size: 30px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    margin-block: 30px;
  }

  & p,
  ol,
  ul {
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }

  & .bold {
    font-weight: 600;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    padding: 40px 0;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  padding-inline: 25px;

  @media (max-width: ${ScreenSizes.narrow}) {
    justify-content: center;
  }
`;

const SubHeaderButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 20px;
  height: 38px;
  cursor: pointer;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 16px;
    padding: 5px 10px;
  }
`;

const subHeader = () => (
  <SubHeaderContainer>
    <a href="/privacy">
      <SubHeaderButton>Privacy</SubHeaderButton>
    </a>
    <a href="/tos">
      <SubHeaderButton>Terms Of Service</SubHeaderButton>
    </a>
    <a href="mailto:support@starform.co">
      <SubHeaderButton>Support</SubHeaderButton>
    </a>
  </SubHeaderContainer>
);

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
}

export default class Tos extends React.Component<{}, MyState> {
  state: MyState = {
    accounts: LSI.getAccounts(),
    activeAccountId: LSI.getActiveAccountId(),
    timeOutNumber: undefined,
  };

  componentDidMount(): void {
    window.addEventListener("focus", () => this.setupTimeoutNumber());
    this.setupTimeoutNumber();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.accounts == undefined && this.state.accounts != undefined) {
      this.setupTimeoutNumber();
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  setupTimeoutNumber(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer();
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  render() {
    const activeAccount = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );
    return (
      <PageWrapper
        subHeader={null}
        activeRoute=""
        fullScreen={false}
        forceLogin={true}
        backgroundImage={backgroundImage}
        accounts={this.state.accounts}
        activeAccount={activeAccount}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({ activeAccountId: account.accountId });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={1}
        intendingToDelete={true}
      >
        <ContentDiv
          smallDevice={isSmallScreen()}
          hasSubHeader={false}
          fullScreen={false}
        >
          {null}
        </ContentDiv>
      </PageWrapper>
    );
  }
}
